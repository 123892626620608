<template>
  <div>
    <custom-breadcrumb
        :pageTitle="$t('resources.catalogs')"
        :breadcrumb="[
          {
            to: 'resources',
            text: this.$t('Educational Resources')
          },
        ]"
    ></custom-breadcrumb>

    <b-row>
      <b-col cols="12">
        <b-card>
          <b-row>
            <b-col
              md="6"
              class="mb-1"
            >
            <b-form-input
              autofocus
              type="text" 
              v-model="searchText" 
              maxlength="64"
              :placeholder="$t('resources.search_placeholder')"
              v-on:keyup.enter="search"
            />
            </b-col>
            <b-col
              md="3"
              class="mb-1"
            >
              <b-button block @click="search"  variant="primary">
                <feather-icon              
                  icon="SearchIcon"
                  size="16"
                />
              </b-button>
            </b-col>
            <b-col
              md="3"
              class="mb-1"
            >
              <b-button block @click="clear"  variant="secondary">
                <feather-icon              
                  icon="XIcon"
                  size="16"
                />
              </b-button>
            </b-col>
          </b-row>
        </b-card>
        <b-row v-if="userData.id == 1081 || userData.role == 'admin'">
          <b-col>
            <b-button :to="{name:'catalogs-create'}" variant="success" class="float-right mb-2"
              v-b-tooltip.hover.top="$t('resources.create_catalog')"
            >
              <feather-icon              
                icon="PlusIcon"
                size="16"
              />
            </b-button>
          </b-col>
        </b-row>
        <b-list-group v-if="filtered_catalogs.length>0">
              <b-list-group-item v-for="catalog in filtered_catalogs" :key="catalog.id" class="d-flex justify-content-between align-items-center">
                <span class="d-flex" >
                  
                  <span class="mr-1">{{ catalog.name }}</span>
                  <b-badge class="mr-1" pill variant="primary">{{ catalog.lang.iso_code.toUpperCase() }}</b-badge>
                  <feather-icon
                    v-b-tooltip.hover.top="$t('resources.doc_has_errors')"
                    v-if="catalog.status == 'KO'"
                    icon="AlertTriangleIcon"
                    size="16"
                    color="darkorange"
                    class="mr-1"
                  />
                  <b-spinner small v-if=" catalog.status == 'pending' " variant="primary" :label="$t('resources.processing')" />
                </span>
                <span >
                  <!-- Download -->
                  <a class="pointer ml-1" v-if="$can('read', 'Catalogs') && catalog.status == 'OK'"
                      target="blank" 
                      :download="catalog.name" 
                      :href="catalog.aws_s3_url_public">
                      <feather-icon
                        v-b-tooltip.hover.top="$t('resources.download')"
                        icon="DownloadIcon"
                        size="16"
                        color="secondary"
                      />
                  </a>
                <template>
                  <!--  v-if="$store.getters['user/getUserData'].id == 1081" -->
                  <!-- Delete catalog -->
                  <a class="pointer ml-1" 
                    v-if="$can('delete', 'Catalogs') && catalog.status != 'pending'"
                    @click.prevent="deleteModel(catalog.id)" >
                      <feather-icon
                        v-b-tooltip.hover.top="$t('resources.delete')"
                        icon="TrashIcon"
                        size="16"
                        color="tomato"
                      />
                  </a>

                  <!-- Publish catalog -->
                  <a class="pointer ml-1" 
                    v-if="$can('edit', 'Catalogs') && catalog.status != 'pending'"
                    @click.prevent="publishCatalog(catalog.id)" >
                      <feather-icon
                        v-b-tooltip.hover.top="catalog.active ? $t('resources.unpublish') : $t('resources.publish') "
                        :icon="catalog.active ? 'EyeIcon' :'EyeOffIcon'"
                        size="16"
                        color="grey"
                      />
                  </a>
                  </template>
                </span>
              </b-list-group-item>
        </b-list-group>
        <p v-else class="text-center" style="color: gray; margin-top:40px;">{{$t('resources.no_docs_found_extended')}}</p>
      </b-col>
    </b-row>
  </div>

</template>

<script>
import { getCatalogs, publishCatalogs, deleteCatalogs } from '@/api/routes'
import CustomBreadcrumb from '@/views/components/breadcrumb/CustomBreadcrumb.vue'

import {
  BFormInput,BButton,
  BListGroup, BListGroupItem, BBadge, VBTooltip,BSpinner,
  BRow, BCol, BCardGroup, BCard, BCardText, BCardTitle, BCardFooter, BCardBody,
} from 'bootstrap-vue'
import { decryptData } from '@/auth/utils'

export default {
  components: {
    BFormInput,
    BButton,
    BRow,
    BCol,
    BCardGroup,
    BCard,
    BCardText,
    BCardTitle,
    BCardFooter,
    BCardBody,BSpinner,
    BListGroup, BListGroupItem, BBadge,
    CustomBreadcrumb
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data(){
    return {
        userData: decryptData(localStorage.getItem('userData')),

        filtered_catalogs: [],
        catalogs: [],
        ajaxLoading: false,

        dialogMessage: '',
        dialogIcon: '',

        searchText: '',
        searching: false,

        emptyResults: false,
    }
  },
  mounted(){
    this.getData()
  },
  computed:{
    lang(){
      return this.$store.getters['user/getLang']
    }
  },
  watch:{
    lang(newLang, oldLang){
      this.getData()
    }
  },
  methods:{
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
    async getData(){
      await this.$http.post(getCatalogs, {}).then( response => {
        if(response.data.status == 200){
          //this.videos = response.data.videos
          // console.log(response.data)

          this.filtered_catalogs = response.data.catalogs
          this.catalogs = response.data.catalogs
          
          if(this.searchText){
            this.search()
          } else {
            if (this.catalogs.length == 0) this.emptyResults = true
            else this.emptyResults = false
          }
          
        } else {
          this.makeToast('danger', this.$t('Error'), this.$t('error_retry_later'));
        }
      })
    },
    search(){
      if(this.searchText == ''){
        this.filtered_catalogs = this.catalogs;
      } else {
        let this_catalogos = []
        
          this.catalogs.forEach(element => {
            if(element.name.toLowerCase().includes(this.searchText.toLowerCase())){
              this_catalogos.push(element)
            }
          });
          this.filtered_catalogs = this_catalogos;
          if (this.filtered_catalogs.length == 0) this.emptyResults = true
          else this.emptyResults = false
        }
    },
    clear(){
      this.searchText  = '';
      this.filtered_catalogs = this.catalogs;
    },
    async publishCatalog(cat_id){
      let data = {
        catalog_id: cat_id
      }
      await this.$http.post(publishCatalogs, data).then( response => {
        if(response.data.status == 200){
          //this.videos = response.data.videos
          this.getData()
          
        } else {
          this.makeToast('danger', this.$t('Error'), this.$t('error_retry_later'));
        }
      }).catch(error => {
          // console.log("Err -> ", error);
          this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
        })
    },
    deleteModel(id){
          this.$bvModal
            .msgBoxConfirm(this.$t('table.delete_one_confirmation'), {
              title: this.$t('resources.delete'),
              size: 'sm',
              okVariant: 'primary',
              okTitle: 'Yes',
              cancelTitle: 'No',
              cancelVariant: 'outline-secondary',
              hideHeaderClose: false,
              centered: true,
            })
            .then(value => {
              if(value){
                let data = {
                  'catalog_id': id
                }
                this.$http.post(deleteCatalogs, data).then( response =>{
                  if(response.data.status == 200){
                    this.getData()
                    this.makeToast('success', this.$t('Success'), this.$t('table.delete_success'));

                  } else {
                    this.makeToast('danger', this.$t('Error'), this.$t('table.delete_error'));
                  }

                }).catch(error => {
                  // console.log("Err -> ", error)
                })
              }
            })
    },
    
  }
}
</script>

<style>

</style>